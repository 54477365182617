

































































import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import OrganisationBase from "@/components/OrganisationBase.vue";
import OrganisationSettings from "@/components/OrganisationSettings.vue";
import { Organisation } from "@/models/Organisation";
import { GeoArea } from "@/models/GeoArea";
import { OrganisationSettingsModel } from "@/models/OrganisationSettingsModel";
import { organisationModule } from "@/store/modules/OrganisationModule";
import moment from "moment";

@Component({
  components: {
    OrganisationBase,
    OrganisationSettings,
  },
})
export default class OrganisationAdd extends Vue {
  organisation: Organisation = {
    id: undefined,
    name: "",
    key: "",
    areaId: undefined,
  } as Organisation;
  geoAreas: GeoArea[] = [];
  organisations: Organisation[] = [];
  settings: OrganisationSettingsModel = {
    id: undefined,
    organisationId: undefined,
    ftpHostname: "",
    ftpPath: "",
    ftpUsername: "",
    ftpPassword: "",
    isDownloadEnabled: false,
    startYear: moment().year(),
  } as OrganisationSettingsModel;
  copyConversionFactorsFromOrganisationId?: string = undefined;

  isInitialised = () => {
    return true;
    // return this.geoAreas.length && this.organisations.length;
  }

  async save() {
    const organisationId = await adminService.createOrganisation({
      organisation: this.organisation,
      settings: this.settings,
      copyConversionFactorsFromOrganisationId: this.copyConversionFactorsFromOrganisationId,
    });

    await organisationModule.dispatchLoad({ organisationId });
    
    this.$router.push("/organisation");
  }

  private async activated() {
    this.geoAreas = await adminService.getGeoAreas();
    this.organisations = await adminService.getOrganisations();
  }
}
